export const Url = {
  loginUrl: "/login",
  InstituteUrl: "/institute",
  clubsUrl: "/club",
  updateInstituteUrl: "/institute?_id=",
  getClubUrl: "/club?_id=",
  updateClubUrl: "/club?_id=",
  deleteInstituteUrl: "/institute?_id=",
  deletClubsUrl: "/club?_id=",
  uploadLogoUrl: "/institute/logo/",
  uploadPrimaryLogo: "/institute/add/logo?id=",
  getLogoUrl: "/institute/logos?id=",
  uploadClubLogo: "/club/logo/",
  getClubLogo: "/club/logos?id=",
  informationFiles: "/add/?id=",
  uploadTicketLogo: "/club/logo/?id=",
  getHiddenAttributes: "institute/view?id=",
  ViewClubHiddenAttribute: "club/ticket/view?id=",
  updateOrders: "institute/update/order?_id=",
  updateClubOrder: "club/update/order?_id=",
  getAllAttributes: "institute/view/order?id=",
  viewClubAttributes: "club/view/order?id=",
  idCustomisationLogos: "institute/url?id=",
  clubLogoUrl: "club/url?id=",
  CreateManager: "/manager",
  ViewClubManager: "/club/viewmanagers?id=",
  viewSingleManager: "/club/viewmanager?id=",
  updateManager: "club/manager/update?id=",
  deleteManager: "/club/manager/delete?id=",
  studentInfo: "/student/id?id=",
  deleteInstituteLogo: "/institute/logo/delete?url=",
  deleteClubLogo: "/club/logo/delete?url=",
  clubLogoPrimary: "/club/add/logo?id=",
  toggleInstituteIdStatus: "/admin/institute/id/status?id=",
  toggleClubIdStatus: "/admin/club/id/status?id=",
  countUsers: "/admin/register/users",
  hangerCount: "/hanger/count",
  ticketCount: "admin/ticket/count?",
  ticketCountType: "/admin/ticket/allcount?",
  userCounter: "/admin/users/ticket?id=",
  coatCounter: "/admin/coat/collected?id=",
  leftCoat: "/admin/club/coats?id=",
  coatHungingTime: "/admin/club/avgcoathungtime?id=",
  userApplicationDuration: "/admin/user/durations?id=",
  qrCount: "/admin/student/qr/count?",
  instituteStudent: "/institute/allstudent?id=",
  analyticUsersYear: "/admin/users/dateofbirth/gender/count?birth_year=",
  analyticUsersGender: "/admin/users/dateofbirth/gender/count?gender=",
  appUsageRecords: "/admin/app/count?",
};
