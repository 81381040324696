import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { FaHome, FaCity, FaChartLine } from "react-icons/fa";
import { message, Modal } from "antd";
import {
  LogoutOutlined,
  PictureOutlined,
  FileOutlined,
  EditOutlined,
  FormOutlined,
  UserOutlined,
  CrownFilled,
  AreaChartOutlined,
} from "@ant-design/icons";
import { deleteToken } from "../../../utils/localStorage";
import siteLogo from "../../../assets/images/NLlogo.svg";
import "./Sidebar.scss";
import { useAppState } from "../../../utils/context";

const Sidebar = ({ children, sidebarStatus }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { state, dispatch } = useAppState();
  const clubID = state.clubId != id ? state.clubId : id;

  const toggle = () => setIsOpen(!isOpen);

  const menuItem = [
    {
      path: "/home",
      name: "Home",
      icon: <FaHome />,
    },
    {
      path: "/institution",
      name: "Institutions",
      icon: <FaCity />,
    },
    {
      path: "/club",
      name: "Clubs",
      icon: <CrownFilled />,
    },
  ];
  const InstituteMenuItem = [
    {
      path: "/home",
      name: "Home",
      icon: <FaHome />,
    },
    {
      path: `/institute/idcustomisation/${id}`,
      name: "ID Customisation",
      icon: <EditOutlined />,
    },
    {
      path: `/institute/instituteLogogallery/${id}`,
      name: "Logo Gallery",
      icon: <PictureOutlined />,
    },
    {
      path: `/institute/informationFiles/${id}`,
      name: "Information Files",
      icon: <FileOutlined />,
    },
  ];
  const ClubMenuItem = [
    {
      path: "/home",
      name: "Home",
      icon: <FaHome />,
    },
    {
      path: `/club/ticketcustomisation/${clubID}`,
      name: "Ticket Customisation",
      icon: <FormOutlined />,
    },
    {
      path: `/club/clubLogogallery/${clubID}`,
      name: "Logo Gallery",
      icon: <PictureOutlined />,
    },
    {
      path: `/club/manager/${clubID}`,
      name: "Manager",
      icon: <UserOutlined />,
    },
  ];

  const AnalyticsMenuItem = [
    {
      path: "/home",
      name: "Home",
      icon: <FaHome />,
    },
    {
      path: "/analytics",
      name: "Analytics",
      icon: <FaChartLine />,
    },
    {
      path: "/analytics/clubAnalytics",
      name: "Club Analytics",
      icon: <CrownFilled />,
    },
    {
      path: "/analytics/instituteAnalytics",
      name: "Institute Analytics",
      icon: <FaCity />,
    },
    {
      path: "/analytics/analyticUsers",
      name: "Users",
      icon: <UserOutlined />,
    },
    {
      path: "/analytics/applicationAnalytics",
      name: "Application Analytics",
      icon: <AreaChartOutlined />,
    },
  ];

  const modalLogout = () => {
    setShowModal(false);
    dispatch({
      type: "SAVE_CLUB_ID",
      payload: 0,
    });
    deleteToken();
    message.success("Logout successfully");
    navigate("/login");
  };

  const logout = () => {
    setShowModal(true);
  };

  return (
    <div className="container">
      <div
        style={{
          width: isOpen ? "350px" : "80px",
        }}
        className="sidebar"
      >
        <div className="top_section">
          <div className="bars">
            <img
              src={siteLogo}
              onClick={toggle}
              className={`${isOpen ? "sidebarOpen" : "sidebarClose"}`}
            />
          </div>
        </div>
        <div className="sidebarContentStyle">
          <div>
            {sidebarStatus === "club"
              ? ClubMenuItem.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={index}
                    className="link"
                    activeclassname="active"
                    style={{ justifyContent: isOpen ? "flex-start" : "center" }}
                  >
                    <div className="icon">{item.icon}</div>
                    <div
                      style={{ display: isOpen ? "block" : "none" }}
                      className="link_text"
                    >
                      {item.name}
                    </div>
                  </NavLink>
                ))
              : sidebarStatus === "institute"
              ? InstituteMenuItem.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={index}
                    className="link"
                    activeclassname="active"
                    style={{ justifyContent: isOpen ? "flex-start" : "center" }}
                  >
                    <div className="icon">{item.icon}</div>
                    <div
                      style={{ display: isOpen ? "block" : "none" }}
                      className="link_text"
                    >
                      {item.name}
                    </div>
                  </NavLink>
                ))
              : sidebarStatus === "analytics"
              ? AnalyticsMenuItem.map((item, index) => {
                  return (
                    <NavLink
                      to={item.path}
                      key={index}
                      className="link"
                      end
                      activeclassname="active"
                      style={{
                        justifyContent: isOpen ? "flex-start" : "center",
                      }}
                    >
                      <div className="icon">{item.icon}</div>
                      <div
                        style={{ display: isOpen ? "block" : "none" }}
                        className="link_text"
                      >
                        {item.name}
                      </div>
                    </NavLink>
                  );
                })
              : menuItem.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={index}
                    className="link"
                    activeclassname="active"
                    style={{ justifyContent: isOpen ? "flex-start" : "center" }}
                  >
                    <div className="icon">{item.icon}</div>
                    <div
                      style={{ display: isOpen ? "block" : "none" }}
                      className="link_text"
                    >
                      {item.name}
                    </div>
                  </NavLink>
                ))}
          </div>
          <div>
            <div
              style={{
                color: "black",
                justifyContent: isOpen ? "flex-start" : "center",
              }}
              className="logoutstyle"
              onClick={logout}
            >
              <div className="icon">
                <LogoutOutlined />
              </div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
      <main>{children}</main>
      <Modal
        width={400}
        title="Logout"
        open={showModal}
        onCancel={() => setShowModal(false)}
        okText="Logout"
        onOk={modalLogout}
      >
        Are you sure you want to Logout?
      </Modal>
    </div>
  );
};

export default Sidebar;
